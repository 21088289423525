


export const storeProducts = [
    {
        id:1,
        title:"Wonderful Design",
        imgname: "./img/forpras1.png",
        price: 250,
        company: "Forprasroyal",
        info: "This is a product with a unique difference, we have our customers at heart",
        inCart: false,
        count:0,
        total:0,
    },
    {
        id:2,
        title: "Great Beauty",
        imgname: "./img/forpras2.png",
        price: 150,
        company: "Forprasroyal",
        info: "This is a product with a unique difference, we have our customers at heart",
        inCart: false,
        count:0,
        total:0,
    },
    {
        id:10,
        title: "Learning Blocks",
        imgname: "./img/alphabetBlocks.jpeg",
        price: 390,
        company: "Forprasroyal",
        info: "Help your kids learn, this is an exceptional tool.",
        inCart: false,
        count:0,
        total:0,
    },
    {
        id:11,
        title: "Blue Suit",
        imgname: "./img/blueSuit.jpeg",
        price: 200,
        company: "Forprasroyal",
        info: "This is a product with a unique difference, we have our customers at heart",
        inCart: false,
        count:0,
        total:0,
    },
    {
        id:12,
        title: "Sports Bycicle",
        imgname: "./img/bycicle1.jpeg",
        price: 4250,
        company: "Forprasroyal",
        info: "This is a product with a unique difference, we have our customers at heart",
        inCart: false,
        count:0,
        total:0,
    },
    {
        id:3,
        title: "Awesome Great",
        imgname: "./img/forpras3.png",
        price: 50,
        company: "Forprasroyal",
        info: "This is a product with a unique difference, we have our customers at heart. ",
        inCart: false,
        count:0,
        total:0,
    },
    {
        id:4,
        title:"Distict Pattern",
        imgname: "./img/forpras4.png",
        price: 750,
        company: "Forprasroyal",
        info: "This is a product with a unique difference, we have our customers at heart",
        inCart: false,
        count:0,
        total:0,
    },
    {
        id:5,
        title: "Wow Pattern",
        imgname: "./img/forpras5.png",
        price: 7850,
        company: "Forprasroyal",
        info: "This is a product with a unique difference, we have our customers at heart",
        inCart: false,
        count:0,
        total:0,
    },
    {
        id:6,
        title: "Elaborate Design",
        imgname: "./img/forpras6.png",
        price: 650,
        company: "Forprasroyal",
        info: "This is a product with a unique difference, we have our customers at heart",
        inCart: false,
        count:0,
        total:0,
    },
    {
        id:7,
        title: "Simply Splendid",
        imgname:"./img/forpras7.png",
        price: 550,
        company: "Forprasroyal",
        info: "This is a product with a unique difference, we have our customers at heart",
        inCart: false,
        count:0,
        total:0,
    },
    {
        id:8,
        title: "Absolute beauty",
        imgname: "./img/forpras8.png",
        price: 2250,
        company: "Forprasroyal",
        info: "This is a product with a unique difference, we have our customers at heart",
        inCart: false,
        count:0,
        total:0,
    },
    {
        id:9,
        title: "Fresh Cookies",
        imgname: "./img/cookies.jpeg",
        price: 800,
        company: "Forprasroyal",
        info: "This is a product with a unique difference, we have our customers at heart",
        inCart: false,
        count:0,
        total:0,
    },
    
    {
        id:13,
        title: "Speed Bike",
        imgname: "./img/bycicle2.webp",
        price: 3000,
        company: "Forprasroyal",
        info: "A bycicle with groundbreaking technologies, this is an absolute beauty.",
        inCart: false,
        count:0,
        total:0,
    },
    {
        id:14,
        title: "Checked Suit",
        imgname: "./img/checkedSuit.jpeg",
        price: 9000,
        company: "Forprasroyal",
        info: "This is a product with a unique difference, we have our customers at heart",
        inCart: false,
        count:0,
        total:0,
    },
    {
        id:15,
        title: "Playing Ducks",
        imgname: "./img/duck.jpeg",
        price: 400,
        company: "Forprasroyal",
        info: "This is a product with a unique difference, we have our customers at heart",
        inCart: false,
        count:0,
        total:0,
    },
    {
        id:16,
        title: "Playing Dull",
        imgname: "./img/dull.webp",
        price: 640,
        company: "Forprasroyal",
        info: "This is a product with a unique difference, we have our customers at heart",
        inCart: false,
        count:0,
        total:0,
    }
];

export const detailProduct = {
        id:1,
        title: "Incredible and Awesome",
        imgname:"./img/forpras9.jpg",
        price: 850,
        company: "Forprasroyal",
        info: "This is a product with a unique difference, we have our customers at heart. We appreciate your support",
        inCart: false,
        count:0,
        total:0,
};