import React from 'react'

export default function EmptyCart() {
    return (
         
        <div className=" d-flex empty-cart justify-content-center pt-10 align-items-center text-danger">
            The Cart is Empty
        </div>
    )
}
