import React, { Component } from 'react';

export default class Default extends Component {
    render() {
        return (
            <div>
                <h3>Page not found</h3>
            </div>
        )
    }
}
